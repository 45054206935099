import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import vikit_logo from "../../assets/vikit_logo_white.png";
// import logo from '../../logo.svg';
import './navbar.css';

const Navbar = ({WebsiteMode}) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          {/* <img src={vikit_logo} width="160%" height="100%"/> */}
          <h1 className="logo-text">{WebsiteMode == "Podcast"?(<div>Podcast AI Video Generation</div>):(<div><img src={vikit_logo}></img></div>)}</h1>
        </div>
        <div className="gpt3__navbar-links_container">
          <p>
            <a href="#home">Home</a>
          </p>
          {/*<p>
            <a href="#wgpt3">{WebsiteMode == "Podcast"?(<div>Podcast AI Video Generation</div>):(<div>Enterprise AI Video Generation</div>)}</a>
  </p>*/}
           <p>
                <a href="#try">Try out our tech</a>
              </p>
          <p>
          <a href="#developers">For developers</a>
          </p>
          <p>
          <a href="#footer">Contact us</a>
          </p>
          {/* <p><a href="#features">Case Studies</a></p> */}
          {/* <p><a href="#blog">Library</a></p> */}
        </div>
      </div>

      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <p>
                <a href="#home">Home</a>
              </p>
              <p>
                <a href="#try">Try out our tech</a>
              </p>
              <p>
                <a href="#footer">Contact us</a>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
