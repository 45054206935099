import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "@firebase/auth";

import firebaseConfig from '../firebaseConfig';
import {initializeApp } from "firebase/app";
import React, { useState, useEffect } from "react";
import { getFirestore, addDoc, setDoc, getDoc, doc, collection } from "firebase/firestore";


import PlatformDisconnected from "./PlatformDisconnected"
import PlatformConnected from "./PlatformConnected"







const Platform = () => {

    const fireBaseApp = initializeApp(firebaseConfig);

    // Initialize Firebase Authentication and get a reference to the service
    const auth = getAuth(fireBaseApp);

    // Initialize Cloud Firestore and get a reference to the service
    const db = getFirestore(fireBaseApp);

    var user = auth.currentUser;

    const [connected, setConnected] = useState(2);
    // Initialize Firebase
    

    
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setConnected(1)
                console.log("connectedchange")
            } else {
                setConnected(0)
            }
        });
    })
    return connected == 1 ? (  <PlatformConnected fireBaseApp={fireBaseApp} auth={auth} db={db} setConnected={setConnected} />) : connected == 0 ?  (  <PlatformDisconnected fireBaseApp={fireBaseApp} auth={auth} db={db} setConnected={setConnected} />) : (<div></div>)

}

export default Platform;