
import React, { useState, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import './css/normalize.css';
import './css/global.css';
import {getConfig, cancelSubscription} from "./script" 
import { getFirestore, addDoc, setDoc, getDoc, doc, deleteDoc, collection } from "firebase/firestore";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

import { HashLink } from 'react-router-hash-link';
import FormControlLabel from '@mui/material/FormControlLabel';



const PlatformConnected = ({fireBaseApp, auth, db, setConnected}) => 
  {
    const docRef = doc(db, 'users', auth.currentUser.uid);
    const [vikitAPIKey, setVikitAPIKey] = useState("");
    const [stripeId, setStripeId] = useState("");
    const [idToken, setIdToken] = useState("");
    const [GPUA100Consumption, setGPUA100Consumption] = useState(0);
    const [GPUA100ConsumptionCredits, setGPUA100ConsumptionCredits] = useState(0);
    const [stripeSubscriptionId, setStripeSubscriptionId] = useState("");
    const [hasStripeSubscription, setHasStripeSubscription] = useState(false);
    const [displayVikitAPIKey, setDisplayVikitAPIKey] = useState(false);
    const [displaySubscriptionForm, setDisplaySubscriptionForm] = useState(false);
    const [backendMessage, setBackendMessage] = useState("");
    const [displayedApiGenerations, setDisplayedApiGenerations] = useState(Array(50));
    const [videoAPIText, setVideoAPIText] = useState();
    const [videoAPIInclude_read_aloud_prompt, setVideoAPIInclude_read_aloud_prompt] = useState(true);
    const [videoAPIgenerate_background_music, setVideoAPIgenerate_background_music] = useState(false);
    const[paymentNeededToPostVideoText , setPaymentNeededToPostVideoText] = useState(false)
    const[videoAPIHasBeenSent , setVideoAPIHasBeenSent] = useState(false)


    function timeConverter(UNIX_timestamp){
      var a = new Date(UNIX_timestamp * 1000);
      var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      var sec = a.getSeconds();
      var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
      return time;
    }

    const onInputVideoTextChange = async(e) =>{
      e.preventDefault();
      if (e.target.value.length <=250){
        setPaymentNeededToPostVideoText(false)
      } else if (!hasStripeSubscription) {
        setPaymentNeededToPostVideoText(true)
      }
      await setVideoAPIText(e.target.value);
    }

    const refreshDataFromDataBase = () => {
      getDoc(docRef).then(docSnap => {
        console.log(docSnap)
        if (docSnap.data() != undefined && docSnap.data().vikitAPIKey != null){
            setVikitAPIKey(docSnap.data().vikitAPIKey)
            setStripeId(docSnap.data().stripeId)
            console.log(docSnap.data())
            if (docSnap.data().GPUA100Consumption > 0){
              setGPUA100Consumption(docSnap.data().GPUA100Consumption)
            } else{
              setGPUA100ConsumptionCredits(docSnap.data().GPUA100Consumption*-1)
            }
            setHasStripeSubscription(docSnap.data().hasStripeSubscription)
            if (docSnap.data().hasStripeSubscription){
              console.log(docSnap.data().stripeSubscriptionId)
              setStripeSubscriptionId(docSnap.data().stripeSubscriptionId)
            }
            refreshDisplayedApiGenerations(docSnap.data().vikitAPIKey)
        } 
        setBackendMessage("");
      })
    }

    const refreshDisplayedApiGenerations = (vikitApiKey) => {
      var seconds = Math.floor(new Date().getTime() / 1000);
      console.log("seconds" + seconds)
      const url = 'https://api-vikit.replit.app/getvideos';
      const data = {
        vikit_api_key: vikitApiKey,
        maxTimestamp: seconds
      };
        axios.post(url, data).then(response => {
        setDisplayedApiGenerations(response.data);
        }
      )
    }

    const createVideoFromAPI = () => {
      const url = 'https://api-vikit.replit.app/createvideo';
      const data = {
        vikit_api_key: vikitAPIKey,
        model: "haiper",
        include_read_aloud_prompt: videoAPIInclude_read_aloud_prompt,
        generate_background_music: videoAPIgenerate_background_music,
        prompt: videoAPIText
      };
        axios.post(url, data)
        setVideoAPIHasBeenSent(true)
        setTimeout(function() {
          refreshDataFromDataBase();
        }, 2000);
        
    }

    useEffect(() => {
      refreshDataFromDataBase();
      auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
        setIdToken(idToken)
      })
      
    }, []);

    useEffect(() => {
      const interval = setInterval(() => {
        refreshDataFromDataBase();
      }, 20000); // 20000 milliseconds = 20 seconds
  
      // Cleanup the interval on component unmount
      return () => clearInterval(interval);
    }, []); // Empty dependency array means this effect runs once after the initial render
  

  return (
  <body class="antialiased p-6">
    <nav class="flex items-center justify-between flex-wrap">
      <div>
        <button
          class="bg-white hover:bg-white hover:shadow-outline hover:text-pasha hover:border hover:border-black focus:shadow-outline text-pasha focus:bg-white focus:text-pasha font-light py-2 px-4 rounded"
          type="button"
          onClick={() => {auth.signOut().then(function() {
            console.log('Signed Out');
          }, function(error) {
            console.error('Sign Out Error', error);
          });}}
        >
          Sign out
        </button>
      </div>
    </nav>
    <div id="prices-and-payment" class="flex flex-wrap justify-center">
      <div id="prices-form" class="md:w-1/3 w-full mr-4 md:mb-8">
      {backendMessage ? (<div class="text-center font-bold text-gray-700">Subscription cancelled</div>):(<div></div>)}
        <div class="text-center text-pasha font-bold text-2xl mt-4 mb-6">
          
          Welcome {auth.currentUser.displayName}<br></br><br></br>
          </div>
          { videoAPIHasBeenSent ? (<div>

            <b>Your video has been sent for generation, processing times are typically 10 minutes. <br></br>
            You can see the state of the generation on           <HashLink class="text-blue" to="/platform/#generations">this page bottom.</HashLink> </b>
          </div>) : (
          <div><b>Generate a video from text</b>
          <TextField
        id="standard-multiline-flexible"
        placeholder="Type here how a narrator would decribe the video sentence by sentence."
        multiline
        rows={2}
        maxRows={7}
        style={{ width: '100%'}}
        onChange={onInputVideoTextChange}  

        helperText={videoAPIText&&!hasStripeSubscription?videoAPIText.length + "/" + "250 characters":""}
      /> 
<FormControlLabel
            control={
                <Checkbox
                    checked={videoAPIInclude_read_aloud_prompt}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={() => setVideoAPIInclude_read_aloud_prompt(!videoAPIInclude_read_aloud_prompt)}

                />
            }

            label="Include narrator voice"
        />
        <FormControlLabel
            control={
                <Checkbox
                    checked={videoAPIgenerate_background_music}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={() => setVideoAPIgenerate_background_music(!videoAPIgenerate_background_music)}

                />
            }

            label="Include AI background music"
        />


      {
        paymentNeededToPostVideoText?(
        <div class="text-center text-pasha font-bold text-base mt-4 mb-6">You need to insert your payment details to generate videos with more than 250 characters.</div>
        ) : GPUA100Consumption > 5000 && !hasStripeSubscription ? (<div class="text-center text-pasha font-bold text-base mt-4 mb-6"> You already spent your 5000 monthly free credits. Insert your payment details to generate more AI videos.</div>) : (
        <div>
<div class="flex justify-center mt-6">
            <button
              id="submit-premium"
              onClick={() => {
                createVideoFromAPI();
              
              }}
              class="bg-pasha hover:bg-red-400 outline-none hover:text-pasha hover:border hover:border-black text-white font-light py-2 px-4 rounded-lg"
              type="submit"
            >
              <div
                id="submit-premium-button-text"
                class="w-auto -mx-2 md:mx-0"
              >
                Create video
              </div>
            </button>
          </div>


        </div>)}
      </div>

      
    
    )
          }
          

        {!hasStripeSubscription?(<div>
        {GPUA100ConsumptionCredits > 0 ? (<div><b>You have </b><div class="text-pasha text-2xl font-extrabold">{GPUA100ConsumptionCredits}</div> <b>free Vikit.ai GPU seconds.</b><br></br><br></br></div>): (<div></div>)}
        <b>You consumed</b> <div class="text-pasha text-2xl font-extrabold">
                {GPUA100Consumption}
              </div> <b>our of your 5000 free GPU seconds this month.</b>
          <br></br><br></br>
          {!displaySubscriptionForm?(<div>
          <div class="flex justify-center mt-6">
                <button
                  id="submit-premium"
                  onClick={() => {
                    setDisplaySubscriptionForm(true);
                    getConfig(stripeId, idToken, setBackendMessage);
                  
                  }}
                  class="bg-pasha hover:bg-red-400 outline-none hover:text-pasha hover:border hover:border-black text-white font-light py-2 px-4 rounded-lg"
                  type="submit"
                >
                  <div
                    id="submit-premium-button-text"
                    class="w-auto -mx-2 md:mx-0"
                  >
                    Subscribe for more GPU seconds !
                  </div>
                </button>
              </div>
          <b></b>
          </div>):(<div>
        <div class="flex justify-center  mb-8">

          <div
            id="premium"
            class="rounded overflow-hidden border rounded-md p-2  "
          >
            <div class="px-2 py-2">
              <div class="text-gray-500 text-xl mb-2 font-medium">
                Consumption billing
              </div>
              <p class="text-pasha text-2xl font-extrabold">
                $0
              </p>
              <div class="flex-wrap">
                <div class="leading-none text-gray-500 text-xs font-medium">
                  For the first 5,000 GPU seconds
                </div>
                <div
                  class="leading-none text-gray-500 text-xs mt-4 font-medium mt-1"
                >
                  <p class="text-pasha text-2xl mb-2">
                    + $.0013225
                  </p>
                </div>
                <div class="leading-none text-gray-500 text-xs font-medium">
                  Per excess GPU second above 5,000
                </div>
                <div
                  class="leading-none text-gray-500 text-xs font-medium mt-4"
                >
                  Billed monthly
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div id="payment-form" class="flex justify-center ">
          <div class="w-full inline-block border p-4 rounded-md">
            <div class="font-bold text-xl mb-2">
              Enter your card and be billed with Stripe
            </div>
            <p class="text-gray-700 text-base">
              → Total due now <span id="total-due-now">$0.00</span><br></br>
              → Monthly free credits reseted 
            </p>
            <p id="price-selected" class="text-gray-700 text-base mb-4"></p>

            <div class="w-full">
              <div class="flex flex-wrap -mx-3 mb-2">
                <div class="w-full px-3 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    Full name
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 border rounded-md py-3 px-2 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="name"
                    type="text"
                    placeholder="First and last name"
                    required
                  />
                </div>
              </div>
              <form id="payment-form">
                <div class="flex flex-wrap -mx-3 mb-3">
                  <div class="w-full px-3 mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Card
                    </label>
                    <div
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded-md py-3 px-2 leading-tight focus:outline-none focus:bg-white"
                      id="card-element"
                    ></div>
                    <div
                      id="card-element-errors"
                      class="text-gray-700 text-base mt-2"
                      role="alert"
                    ></div>
                  </div>
                </div>
                <button
                  id="submit-premium"
                  class="bg-pasha hover:bg-red-400 outline-none hover:text-pasha hover:border hover:border-black text-white font-light py-2 px-4 rounded-lg"
                  type="submit"
                >
                  <div class="">
                    <div id="loading" class="hidden">Subscribing...</div>
                    <span id="button-text" class="">Subscribe</span>
                  </div>
                </button>
              </form>
            </div>
          </div>
        </div></div>)}</div>):(<div><b>Your Vikit.ai subscription is active. <br></br>You consumed</b> <div class="text-pasha text-2xl font-extrabold">
                {Math.round((GPUA100Consumption)*1000) / 1000}
              </div> <b> GPU seconds this month.<br></br>

              <div class="px-2 py-2">
              <div class="text-gray-500 text-xl mb-2 font-medium">
                Consumption billing
              </div>
              <p class="text-pasha text-2xl font-extrabold">
              {5000-GPUA100Consumption <= 0 ? (5000) : (<div>{Math.round((5000-GPUA100Consumption)*1000) / 1000}</div>) }
              </p>
              <div class="flex-wrap">
                <div class="leading-none text-gray-500 text-xs font-medium">
                  Of your free 5,000 GPU seconds
                </div>
                <div
                  class="leading-none text-gray-500 text-xs mt-4 font-medium mt-1"
                >
                  <p class="text-pasha text-2xl mb-2">
                  {GPUA100Consumption-5000 <= 0 ? (0) : (<b>{Math.round((GPUA100Consumption-5000)*1000)/1000}</b>)} * $.0013225 = <b>$</b>{GPUA100Consumption-5000 <= 0 ? (0):(<b>{Math.round((GPUA100Consumption-5000) * 0.0013225 * 1000)/1000}</b>)}
                  </p>
                </div>
                <div class="leading-none text-gray-500 text-xs font-medium">
                  Per excess GPU second above 5,000
                </div>
                <div
                  class="leading-none text-gray-500 text-xs font-medium mt-4"
                >
                  Billed monthly
                </div>
              </div>
              
            </div>

              Next bill: </b><div class="text-pasha text-2xl font-extrabold">${GPUA100Consumption-5000 <= 0 ? (0):(<b>{Math.round((GPUA100Consumption-5000) * 0.0013225 * 1000)/1000}</b>)} billed on&nbsp;
                {
              new Date(new Date().getFullYear(), new Date().getMonth()+1, 0).toString().slice(0,10) 
              }</div>
              <div class="flex justify-center mt-6">
                <button
                  id="submit-premium"
                  onClick={() => {
                    setDisplaySubscriptionForm(false);
                    cancelSubscription(idToken, stripeSubscriptionId).then(result => {

                      setBackendMessage("Subscription cancelled")
                      setHasStripeSubscription(false)
                      setstripeSubscription("")
                    }).catch(error => {
                      setBackendMessage("There was a problem with subscription cancellation: " + error)
                    });
                  
                  }}
                  class="bg-pasha hover:bg-red-400 outline-none hover:text-pasha hover:border hover:border-black text-white font-light py-2 px-4 rounded-lg"
                  type="submit"
                >
                  <div
                    id="submit-premium-button-text"
                    class="w-auto -mx-2 md:mx-0"
                  >
                    Cancel Subscription
                  </div>
                </button>
              </div>
              </div>)}
              {
          displayVikitAPIKey ? (<div class="text-center text-pasha font-bold text-2xl mt-4 mb-6"><button
            id="submit-premium"
            onClick={() => setDisplayVikitAPIKey(false)}
            class="bg-pasha hover:bg-red-400 outline-none hover:text-pasha hover:border hover:border-black text-white font-light py-2 px-4 rounded-lg"
            type="submit"
          >
            <div
              id="submit-premium-button-text"
              class="w-auto -mx-2 md:mx-0"
            >
              Hide Vikit API Key
            </div>
          </button><br></br>Your Vikit API Key is<br></br> <div class="bg-gray-200 text-xl text-gray-700">{vikitAPIKey}</div></div>) : (<div  class="text-center text-pasha font-bold text-2xl mt-4 mb-6">
              <button
                  id="submit-premium"
                  onClick={() => 
                    {
                      setDisplayVikitAPIKey(true);
                      refreshDataFromDataBase();
                    }}
                  class="bg-pasha hover:bg-red-400 outline-none hover:text-pasha hover:border hover:border-black text-white font-light py-2 px-4 rounded-lg"
                  type="submit"
                >
                  <div
                    id="submit-premium-button-text"
                    class="w-auto -mx-2 md:mx-0"
                  >
                    Display Vikit API Key
                  </div>
                </button>


          </div>)

          
          }
          <div style={{'overflow-x':'auto'}} class="text-center text-pasha font-bold text-2xl mt-4 mb-6" id="generations">Your AI Video Generations</div>
          <table class="styled-table">
          <thead>
        <tr>
            <th>Time</th>
            <th style={{width:'500px'}}>Prompt</th>
            <th>State</th>
            <th>Video URL</th>
        </tr>
    </thead>
    <tbody>
      {displayedApiGenerations.map((apiGenetation) => apiGenetation.progress == 100 ? (
        
          
          <tr class="active-row"><th>{timeConverter(apiGenetation.timestamp)}</th><th  style={{width:'500px'}}>{apiGenetation.prompt}</th><th>{apiGenetation.progress}% - {apiGenetation.state}</th><th><a color="blue" href={apiGenetation.url}>Click here for the video URL</a></th></tr>
      ) : (<tr><th>{timeConverter(apiGenetation.timestamp)}</th><th  style={{width:'500px'}}>{apiGenetation.prompt}</th><th>{apiGenetation.progress}% - {apiGenetation.state}</th><th>URL will be displayed here</th></tr>))
      
      }
      </tbody>
    </table>
      </div>


    </div>
  </body>
)
  }

export default PlatformConnected;