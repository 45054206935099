/* eslint-disable*/

import React from 'react';

import { Footer, Possibility, WhatIsDeepfake, Header } from './containers';
import { Navbar } from './components';
// import SampleTamplete  from './SampleTamplete';
import './App.css';
import './index.css';
import HttpsRedirect from 'react-https-redirect';



const App = ({WebsiteMode}) => (
  <HttpsRedirect>
  <div className="App">
    <div className="gradient__bg">
      <Navbar WebsiteMode={WebsiteMode}  />
      <Header WebsiteMode={WebsiteMode} />
    </div>
    {/*<WhatIsDeepfake WebsiteMode={WebsiteMode}  />
    <Possibility WebsiteMode={WebsiteMode}  />*/}
    <Footer WebsiteMode={WebsiteMode}  />
  </div>
  </HttpsRedirect>
);

export default App;
